<template>
  <div class="wrapper">

    <div class="main">
      <div class="intro section">
        <br>
        <br>
        <div class="container" >

          <div class="game_list">
            <span class="game_box" data-tilt>
                <img :src="card_01_shop.en" alt=""/>
                <img :src="card_01_shop.mobile_en" alt=""/>
            </span>

            <span class="game_box" data-tilt>
                <img :src="card_02_paw.en" alt=""/>
                <img :src="card_02_paw.mobile_en" alt=""/>
            </span>

            <span class="game_box" data-tilt>
                <img :src="card_03_nft.en" alt=""/>
                <img :src="card_03_nft.mobile_en" alt=""/>
            </span>
          </div>
        </div>
      </div>
       
      <div class="market-info section " >
        <br>
        <br>
        <div class="container" >
          <h1 class="title">
            <img :src="image_head_title"
                 :alt="language.market_intro_description_title">
          </h1>
          <ul class="sub-title" style="padding-left: 56px;">
            <li class="sub-title-item">
              <span class="icon_box">
                  <img :src="shop_icon_01">
              </span>
              <strong>{{language.market_intro_description_content_01_title}}</strong>
              <span>{{language.market_intro_description_content_01_text}}</span>
            </li>
            <li class="sub-title-item">
              <span class="icon_box">
                  <img :src="shop_icon_02">
              </span>
              <strong>{{language.market_intro_description_content_02_title}}</strong>
              <span>{{language.market_intro_description_content_02_text}}</span>
            </li>
            <li class="sub-title-item">
              <span class="icon_box">
                  <img :src="shop_icon_03">
              </span>
              <strong>{{language.market_intro_description_content_03_title}}</strong>
              <span>{{language.market_intro_description_content_03_text}}</span>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Mixins from "@/plugins/basicMixins";

export default {
  mixins: [Mixins.HeaderImage],
  components: {},
  computed: {
    ...mapGetters([
      'base',
      'locale',
      'language',
    ]),
  },
  bodyClass: "ac-market",
  methods: {
    initGameBox: function () {
      $(".game_box").tilt({
        axis: null,
        maxTilt: 20,
        perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 300, // Speed of the enter/exit transition.
        transition: true, // Set a transition on enter/exit.
        disableAxis: null, // What axis should be disabled. Can be X or Y.
        reset: true, // If the tilt effect has to be reset on exit.
    });
    }
  },
  mounted:  function mounted() {
    this.initGameBox()
  },
  data() {
    return {
      image_head_bg: require("@/assets/img/pages/market/03_pne_bg.png"),
      image_head_title: require("@/assets/img/pages/market/anipangclubshop.png"),
      intro_nft: require("@/assets/img/pages/market/AC_NFT.png"),
      intro_shop: require("@/assets/img/pages/market/AC_SHOP.png"),
      intro_staking: require("@/assets/img/pages/market/AC_STAKING.png"),
      card_01_shop: {
          ko: require("@/assets/img/pages/market/card_01_shop_ko.png"),
          en: require("@/assets/img/pages/market/card_01_shop_en.png"),
          mobile_en: require("@/assets/img/pages/market/card_01_shop_mobile_en.png")
      },
      card_02_paw: {
          ko: require("@/assets/img/pages/market/card_02_paw_ko.png"),
          en: require("@/assets/img/pages/market/card_02_paw_en.png"),
          mobile_en: require("@/assets/img/pages/market/card_02_paw_mobile_en.png"),
      },
      card_03_nft: {
          ko: require("@/assets/img/pages/market/card_03_nft_ko.png"),
          en: require("@/assets/img/pages/market/card_03_nft_en.png"),
          mobile_en: require("@/assets/img/pages/market/card_03_nft_mobile_en.png"),
      },
      shop_icon_01 : require("@/assets/img/pages/market/icon_01.png"),
      shop_icon_02 : require("@/assets/img/pages/market/icon_02.png"),
      shop_icon_03 : require("@/assets/img/pages/market/icon_03.png"),
    };
  },
  
   
};
      
</script>
<!-- <script>
    $('.game_box').tilt({
        axis: null,
        maxTilt: 20,
        perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 300, // Speed of the enter/exit transition.
        transition: true, // Set a transition on enter/exit.
        disableAxis: null, // What axis should be disabled. Can be X or Y.
        reset: true, // If the tilt effect has to be reset on exit.
    });
</script> -->
<style lang="scss">

.market-info h1.title{
    margin-bottom: -23px;
}

@font-face {
  font-family: 'yg-jalnan';
  src: url('../../assets/fonts/Jalnan.ttf') format('truetype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  font-weight: normal;
  font-display: 'auto';
  font-style: normal;
}

$ac-section-bg: #0085ec !default;
$sc-font-color: #00589c !default;

.ac-market {

  .pre-order-item {
    border-radius: 25px;
    background-color: white;
    position: relative;
    text-align: center;
    margin-top: 50px;

    .pre-order-header {
      img {
        width: 100%;
        margin: -30px auto 0;
        display: inline-block;
        border-radius: 20px;
      }
    }
    .pre-order-link-wrap {
      position: absolute;
      bottom: 20px;
      text-align: center;
      width: 100%;

      .ac-link-btn {
        background-color: #001670 !important;
        .md-ripple {

          font-family: 'yg-jalnan';
          padding: 12px 30px;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
    }



  }

  .market-info {
    padding: 20px 0;

    .title {
      text-align: center;
      margin-bottom: 27px;
    }
    .sub-title {
      padding-bottom: 40px;
      margin: 0 30px;
      word-wrap: break-word;
      list-style: none;
      .sub-title-item {
        padding: 0 0 3rem;

        strong {
          font-size: 1.5rem;
          padding-bottom: .5rem;
          display: block;
          font-weight: 800;
          color: #9d5bff !important
        }
        span {
          color: white;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    .content {
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .section {
    background-image: url("../../assets/img/pages/games/preregister/03_pne_bg.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    background-color: #0d083b;

    .section-description {
      text-shadow: 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white;
      -webkit-font-smoothing: antialiased;

      .game-title {
        font-family: 'yg-jalnan';
        color: $ac-section-bg;
        font-size: min(max(100%, 10vw), 300%);
      }
      .game-description {
        color: $ac-section-bg;
        font-size: min(max(50%, 5vw), 150%);
      }
    }
  }
}

.game_list {
    display: flex;
    justify-content: center;
}

.game_box {
    width: 350px;
    height: 547px;
    display: inline-block;
    background-size: cover;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-right: 40px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.game_box img:nth-of-type(2) {
    display: none;
}

.game_box:hover img {
    filter: brightness(1.2);
}

.game_box img {
    width: inherit;
    height: inherit;
    object-fit: contain;
    object-position: bottom;
}

.icon_box {
    width: 52px;
    height: 52px;
    display: inline-block;
    background-size: cover;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    left: 26px;
}

.icon_box img {
    object-fit: contain;
    width: inherit;
    height: inherit;
    object-position: bottom;
}

@media (max-width: 980px) {
  .game_list {
      flex-direction: column;
      padding: 0 40px;
  }

  .game_box {
      width: 100%;
      height: auto;
      margin-bottom: 56px;
  }

  .game_box:nth-last-child(1) {
      margin-bottom: none;
  }

  .game_box img:nth-of-type(1) {
      display: none;
  }

  .game_box img:nth-of-type(2) {
      display: block;
  }

  .market-info.section .sub-title {
      padding-left: 80px !important;
  }

  .icon_box {
      left: 44px;
  }
}

</style>